<template>
  <AlertBox type="warning" :messages="responseError" />
  <SetLoader v-if="!treeViewPageRequestFinish && !responseError.length" />
  <div v-if="treeViewPageRequestFinish && responseError.length == 0">
    <InstantFilterModal
      :pageData="listData"
      v-if="
        listData && listData.filter && listData.filter.isInstantFilterActive
      "
    />
    <div class="dx-viewport mt-2">
      <DxTreeView
        id="treeview"
        :ref="treeViewRef"
        :create-children="createChildren"
        :root-value="''"
        collapseIcon="chevrondown"
        expandIcon="chevronnext"
        :expand-nodes-recursive="false"
        data-structure="plain"
        :searchEnabled="true"
        :noDataText="
          $t(
            'BaseModelFields.NoRecords',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        @content-ready="onContentReady"
        @item-rendered="onItemRendered"
        @item-expanded="onItemExpanded"
        @item-context-menu="treeViewItemContextMenu"
      >
        <template #itemTemplate="{ data }">
          <div class="tree-item-action-blocks">
            <template v-for="button in data.actionButtons" :key="button.uId">
              <button
                v-if="button.type === 'button'"
                type="button"
                class="btn btn-sx-custom-button"
                :class="button.cssClass"
                @click="customActionButtonClick($event, data, button)"
                v-bind="button.attributes"
              >
                <template v-if="!String.isNullOrWhiteSpace(button.iconClass)">
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    data-bs-placement="top"
                    :data-bs-title="button.title"
                  >
                    <i
                      v-if="String.isNullOrWhiteSpace(button.iconColor)"
                      :class="button.iconClass"
                    ></i>
                    <i
                      v-else
                      :style="`color:${button.iconColor} !important`"
                      :class="button.iconClass"
                    ></i>
                  </span>
                  <template v-if="button.label">
                    {{ button.label }}
                  </template>
                </template>
                <template v-else>
                  {{ button.title }}
                </template>
              </button>
              <a
                v-else-if="button.type === 'link'"
                :href="`${button.routeUrl}/${
                  button.appendRowPublicId ? data.record[primaryKey] : ''
                }`"
                class="btn btn-sx-custom-link"
                :class="button.cssClass"
                target="_blank"
                v-bind="button.attributes"
              >
                <i
                  :class="button.iconClass"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-title="button.title"
                ></i>
                {{ button.label }}
              </a>
            </template>
          </div>
          <span class="tree-item-text" v-html="data.text"></span>
          <span class="tree-item-total ms-2"></span>
        </template>
      </DxTreeView>
      <DxContextMenu
        :ref="contextMenuRef"
        v-model:data-source="contextMenuItems"
        target="#treeview .dx-treeview-item"
        @item-click="contextMenuItemClick"
      >
        <template #item="{ data: e }">
          <div>
            <span v-if="e.items" class="dx-icon-spinright"></span>
            {{ e.text }}
          </div>
        </template>
      </DxContextMenu>
    </div>
    <NewRecordModal
      :designedItems="designedItems"
      :pageData="pageData"
      @recordCreated="newRecordCreated"
    />
  </div>
</template>
<script>
// https://js.devexpress.com/Vue/Documentation/Guide/UI_Components/TreeView/Getting_Started_with_TreeView/
import DxTreeView from "devextreme-vue/tree-view";
import DxContextMenu from "devextreme-vue/context-menu";

import NewRecordModal from "@/components/custom/tree/NewRecordModal.vue";
import { showModal, hideModal, removeTooltips } from "@/core/helpers/dom";

import { createToast } from "mosha-vue-toastify";
import InstantFilterModal from "@/components/custom/set-pages/InstantFilterModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import $ from "jquery";
export default {
  name: "TREE",
  props: ["key", "treeId", "filterId"],
  data() {
    return {
      responseError: [],
      treeViewDetail: {},
      listData: {},
      childListDatas: [],
      fields: [],
      treeViewPageRequestFinish: false,
      treeViewRef: "treeView",
      contextMenuRef: "treeViewContextMenu",
      itemLevel: 0,
      pageSize: 9999,
      pageNumber: 1,
      primaryKey: "PUBLICID",
      clickedCurrentButton: null,
      contextMenuItems: [],
      selectedTreeItem: null,

      //new record properties
      designedItems: [],
      pageData: {},
      selectedInfo: {},
      childExpandedItems: [],
      childExpandedNoItems: [],
    };
  },
  components: {
    InstantFilterModal,
    DxTreeView,
    DxContextMenu,
    NewRecordModal,
  },
  computed: {
    treeView() {
      return this.$refs[this.treeViewRef].instance;
    },
    contextMenu() {
      return this.$refs[this.contextMenuRef].instance;
    },
  },
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "tree" && this.treeViewPageRequestFinish) {
        this.getTreeViewPage();
      }
    },
    "$route.params.treeId"() {
      if (this.$route.name === "tree" && this.treeViewPageRequestFinish) {
        this.getTreeViewPage();
      }
    },
    "$route.query.filterId"() {
      if (this.$route.name === "tree" && this.treeViewPageRequestFinish) {
        this.getTreeViewPage();
      }
    },
  },
  methods: {
    onItemExpanded(e) {
      var expandedChildItems = this.childExpandedItems.filter(function (f) {
        return f.parentId == e.node.key;
      });
      if (expandedChildItems.length > 0) {
        expandedChildItems.forEach((expandedItem) => {
          this.treeView.expandItem(expandedItem.id);

          this.childExpandedItems.filter(function (f) {
            return f.id !== expandedItem.id;
          });
        });
      }

      this.$root.createEvent("SXTREE_ON_ITEM_EXPANDED", {
        title: "On item expanded",
        message: "Event triggered when tree on item expanded",
        tree: {
          event: e,
        },
      });
    },
    getBadgeInfo(itemCount, objectName) {
      return `<span class="badge badge-info bg-info">${
        itemCount > 0
          ? this.$t(
              "Components.TreeView.TotalItemFormat",
              {},
              { locale: this.$store.state.activeLang }
            )
              .replaceAll("COUNT", itemCount)
              .replaceAll("OBJECT_NAME", objectName)
          : this.$t(
              "Components.TreeView.NoTotalItemFormat",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("OBJECT_NAME", objectName)
      }</span>`;
    },
    treeViewItemContextMenu(e) {
      this.selectedTreeItem = e.itemData;

      var items = this.treeView.option("items"),
        expandedItems = items.filter(function (f) {
          return f.expanded;
        });

      this.contextMenu.option(
        "items[0].disabled",
        e.node.expanded || !e.itemData.hasItems
      );

      this.contextMenu.option(
        "items[1].disabled",
        !e.node.expanded || !e.itemData.hasItems
      );

      this.contextMenu.option("items[2].disabled", expandedItems.length == 0);

      var treeItems = this.treeViewDetail.treeItems,
        treeItemCount = treeItems.length,
        itemLevel = this.getItemLevel(e.itemData.id),
        itemLevels = [itemLevel - 1],
        showings = [],
        hidings = [];

      itemLevels.forEach((level) => {
        itemLevels = [];

        var current = level,
          parent = current - 1,
          child = current + 1;

        if (parent <= 0) {
          parent = 0;
        } else if (parent > 0) {
          Number.range(parent - 1 <= 0 ? 0 : parent - 1, 0).forEach((x) => {
            hidings.push(x);
          });
        }

        if (level >= 1) {
          hidings.push(parent);
        }

        showings.push(current);
        showings.push(child);

        if (child < treeItemCount) {
          Number.range(child + 1, treeItemCount).forEach((x) => {
            hidings.push(x);
          });
        }
      });

      showings.forEach((index, i) => {
        if (!treeItems[index]) return;
        this.contextMenu.option("items[3].items[" + index + "].visible", true);
      });

      hidings.forEach((index) => {
        this.contextMenu.option("items[3].items[" + index + "].visible", false);
      });
    },
    contextMenuItemClick(e) {
      var modal = $("#treeNewRecordModal"),
        modalFrameBlock = modal.find(".iframe-block"),
        itemData = e.itemData;

      this.pageData = {};
      this.designedItems = [];
      this.selectedInfo = {};

      switch (itemData.id) {
        case "expand":
          this.treeView.expandItem(this.selectedTreeItem.id);
          break;
        case "collapse":
          this.treeView.collapseItem(this.selectedTreeItem.id);
          break;
        case "collapse-all-items":
          this.treeView.collapseAll();
          window.scrollTo(0, 0);
          break;
        case "parent":
          this.getNewPage(this.listData, itemData.id, false);
          showModal(document.getElementById("treeNewRecordModal"));
          break;
        case "child":
          var info = this.selectedTreeItem;
          if (info.pageDto.customObject.publicId !== itemData.relationId) {
            info = this.childListDatas.find(
              (f) =>
                f.customObject.publicId == itemData.relationId &&
                f.parentId == this.selectedTreeItem.id
            );
            if (!info) {
              this.treeView.expandItem(this.selectedTreeItem.id).then(() => {
                info = this.childListDatas.find(
                  (f) =>
                    f.customObject.publicId == itemData.relationId &&
                    f.parentId == this.selectedTreeItem.id
                );
                if (!info) {
                  createToast(
                    this.$t(
                      "BaseModelFields.UnexpectedErrorHasOccurred",
                      {},
                      { locale: this.$store.state.activeLang }
                    ),
                    {
                      showIcon: true,
                      position: "top-right",
                      type: "danger",
                      transition: "zoom",
                    }
                  );
                  return;
                }
                this.getNewPage(info, itemData.id, false);
                showModal(document.getElementById("treeNewRecordModal"));
              });
            } else {
              this.getNewPage(info, itemData.id, false);
              showModal(document.getElementById("treeNewRecordModal"));
            }
          } else {
            this.getNewPage(info, itemData.id, true);
            showModal(document.getElementById("treeNewRecordModal"));
          }
          break;
        default:
          break;
      }
    },
    newRecordCreated(result) {
      var treeViewItems = this.treeView.option("items"),
        parentExpandedItems = treeViewItems.filter(function (f) {
          return f.expanded && String.isNullOrWhiteSpace(f.parentId);
        }),
        isAdhoc = String.isNullOrWhiteSpace(this.$route.query.isAdhoc)
          ? false
          : Boolean.toBool(this.$route.query.isAdhoc);

      var childExpandedNoItems = this.childExpandedNoItems;
      this.childExpandedItems = treeViewItems.filter(function (f) {
        return (
          f.expanded &&
          !String.isNullOrWhiteSpace(f.parentId) &&
          !childExpandedNoItems.includes(f.id)
        );
      });

      treeViewItems = [];
      this.treeView.option("items", treeViewItems);

      //reload tree view data
      this.getParentItems(
        this.$route.params.key,
        isAdhoc
          ? this.$route.query.filterId
          : this.treeViewDetail.viewFilterPublicId
      ).then((response) => {
        var result = response.data;
        this.listData = result;
        this.childListDatas = [];

        this.fields = [];
        result.filter.visibleFields.forEach((filterField) => {
          this.fields.push(filterField.fieldModel);
        });

        if (result.filter.hiddenFields.length > 0) {
          result.filter.hiddenFields.forEach((filterField) => {
            this.fields.push(filterField.fieldModel);
          });
        }

        var items = this.$root.gridDataChange(
            this.listData.pagedItems.items,
            this.fields
          ),
          parentTitleFieldPublicId = this.treeViewDetail.headerFieldPublicId,
          parentTitleField = this.fields.find(
            (f) => f.publicId == parentTitleFieldPublicId
          );

        if (!parentTitleField) {
          createToast(
            this.$t(
              "Components.TreeView.TreeViewLayerTitleNotFound",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("FIELD_NAME", this.treeViewDetail.headerFieldName),
            {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            }
          );
          return [];
        }

        var parentTitles = [],
          buttons = this.getButtons(this.listData);
        items.forEach((item) => {
          var text = item[parentTitleField.formulaName];

          buttons = this.filteredHideButtons(item, this.listData, buttons);

          parentTitles.push({
            hasItems: true,
            id: item[this.primaryKey],
            text: String.isNullOrWhiteSpace(text)
              ? this.$valueNotAvailableSeparator
              : text,
            parentId: null,
            record: item,
            pageDto: this.listData,
            template: "itemTemplate",
            actionButtons: buttons,
            fields: this.fields,
          });
        });

        this.treeView.option("items", parentTitles);
        parentExpandedItems.forEach((item, i) => {
          this.treeView.expandItem(item.id);
        });
      });
    },
    getNewPage(selectInfo, itemType, isTreeItemObject = false) {
      var pageDto = isTreeItemObject ? selectInfo.pageDto : selectInfo,
        customObject = pageDto.customObject,
        isChildItem = itemType === "child";

      var requestModel = {
        customObjectKey: customObject.key,
      };

      this.selectedInfo = { ...selectInfo, itemType: itemType };

      this.$appAxios
        .post("rws-SetController-NewPage", requestModel)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            var newRecordPageData = result;

            if (
              String.isNullOrWhiteSpace(newRecordPageData.layout) ||
              String.isNullOrWhiteSpace(newRecordPageData.layout.layout)
            ) {
              hideModal(document.getElementById("treeNewRecordModal"));
              createToast(
                this.$t(
                  "BaseModelFields.PageLayoutEmpty",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "danger",
                  transition: "zoom",
                }
              );
              return;
            }

            var lookupValue = null,
              lookupFormulaName = null;
            if (isChildItem) {
              lookupFormulaName = pageDto.relationDetail.fieldFormulaName;
              lookupValue = `${this.selectedInfo.parentId}${this.$selectSeparator}${this.selectedInfo.parentText}`;
            }

            if (lookupValue) {
              var fields = newRecordPageData.fields.find(
                  (f) => f.isMainObject
                ).fieldModels,
                currentField = fields.find(
                  (f) => f.formulaName == lookupFormulaName
                );
              if (currentField) {
                currentField.disabled = true;
                currentField.isTreeParentField = true;
              }

              newRecordPageData.fields = [...newRecordPageData.fields];

              var currentRelationFieldValue = newRecordPageData.values.find(
                (f) => f.key === lookupFormulaName
              );
              if (String.isNullOrWhiteSpace(currentRelationFieldValue)) {
                newRecordPageData.values.push({
                  key: lookupFormulaName,
                  value: lookupValue,
                });
              } else {
                currentRelationFieldValue.value = lookupValue;
              }
            }

            this.pageData = newRecordPageData;
            if (newRecordPageData.layout.layout) {
              this.designedItems = JSON.parse(this.pageData.layout.layout);
            }
          } else {
            hideModal(document.getElementById("treeNewRecordModal"));

            if (!String.isNullOrWhiteSpace(result.msg))
              this.responseError.push(result.msg);

            createToast(
              this.errors.length > 0
                ? this.errors[0]
                : this.$t(
                    "BaseModelFields.ErrorMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    getItemLevel(parentId) {
      return parseInt($(`[data-item-id="${parentId}"]`).attr("aria-level"));
    },
    getDocumentJQItem(parentId) {
      return $(`[data-item-id="${parentId}"]`);
    },
    customActionButtonClick($event, rowData, button) {
      var recordPublicId = rowData.record[this.primaryKey],
        customObjectKey = rowData.pageDto.customObject.key,
        clickedButton = $($event.currentTarget).find("button");

      removeTooltips();
      switch (button.name) {
        case "SetXRMCustomButton":
          var currentButton = rowData.pageDto.filter.buttons.find(
            (f) => f.publicId == button.uId
          );
          if (String.isNullOrWhiteSpace(currentButton)) {
            return;
          }

          if (clickedButton.attr("clicked")) {
            return;
          }

          clickedButton.attr("clicked", true).attr("disabled", true);
          setTimeout(() => {
            clickedButton.removeAttr("disabled clicked");
          }, 3000);

          if (currentButton.isShowApprovalMessage) {
            this.$root.buttonApproveOperationForTree(
              this,
              currentButton,
              customObjectKey,
              recordPublicId,
              rowData.record,
              rowData.fields
            );
          } else {
            this.clickedCurrentButton = currentButton;
            this.clickedCustomButton(
              customObjectKey,
              recordPublicId,
              rowData.record,
              rowData.fields
            );
          }
          break;
        default:
          return;
      }
    },
    clickedCustomButton(customObjectKey, recordPublicId, record, fields) {
      var currentButton = this.clickedCurrentButton;
      switch (currentButton.buttonTypeId) {
        case 1:
          this.$root.businessRuleButtonTrigger(
            currentButton,
            customObjectKey,
            recordPublicId
          );
          break;
        case 2:
          this.$root.directForwardButtonTrigger(
            currentButton,
            customObjectKey,
            recordPublicId
          );
          break;
        case 3:
          var values = record,
            currentField = fields.find(
              (f) => f.publicId == currentButton.fieldPublicId
            );
          if (String.isNullOrWhiteSpace(currentField)) {
            currentField = {
              publicId: currentButton.fieldPublicId,
              formulaName: currentButton.fieldFormulaName,
            };
          }

          var fieldFormula = currentField.formulaName,
            valueObj = values[fieldFormula];

          if (String.isNullOrWhiteSpace(valueObj)) {
            var reqModel = {
              customObjectKey: customObjectKey,
              recordPublicId: recordPublicId,
              formulaNames: [fieldFormula],
            };
            this.$root.customForwardButtonTriggerNoPageDataValue(
              currentButton,
              currentField,
              reqModel,
              customObjectKey,
              recordPublicId
            );
          } else {
            this.$root.customForwardButtonTrigger(
              currentButton,
              currentField,
              valueObj,
              customObjectKey,
              recordPublicId
            );
          }
          break;
        case 4:
          var recordDetail = record;
          this.$root.formTemplateButtonTrigger(
            currentButton,
            customObjectKey,
            recordPublicId,
            recordDetail,
            fields
          );
          break;
        default:
          return;
      }
    },
    filteredHideButtons(record, data, buttons) {
      if (!data || !record || !buttons) return [];

      if (!data.hideButtonList || data.hideButtonList.length == 0)
        return buttons;

      var hiddenButtonsForCurrentRecord =
        data.hideButtonList[record[this.primaryKey]];
      if (
        hiddenButtonsForCurrentRecord &&
        hiddenButtonsForCurrentRecord.length > 0
      ) {
        return buttons.filter(
          (f) => !hiddenButtonsForCurrentRecord.includes(f.uId)
        );
      }

      return buttons;
    },
    getButtons(data) {
      if (!data) return [];

      var buttons = [];
      if (data.filter.isDetailButtonActive) {
        buttons.push({
          uId: String.newGuid(),
          name: "DetailRecord",
          label: "",
          title: this.$t(
            "Components.ActionButtons.Detail",
            {},
            { locale: this.$store.state.activeLang }
          ),
          type: "link",
          routeUrl: `#/set/detail/${data.customObject.key}`,
          appendRowPublicId: true,
          cssClass: "btn-primary dx-treeview-record-detail btn-icon",
          iconClass: "fas fa-external-link-alt",
        });
      }

      if (data.filter.isEditButtonActive) {
        buttons.push({
          uId: String.newGuid(),
          name: "EditRecord",
          label: "",
          title: this.$t(
            "Components.ActionButtons.Edit",
            {},
            { locale: this.$store.state.activeLang }
          ),
          type: "link",
          routeUrl: `#/set/edit/${data.customObject.key}`,
          appendRowPublicId: true,
          cssClass: "btn-warning dx-grid-record-edit btn-icon",
          iconClass: "fas fa-edit",
        });
      }

      var filterButtons = data.filter.buttons;
      if (filterButtons.length > 0) {
        filterButtons.forEach((button) => {
          var buttonObj = {
            uId: button.publicId,
            name: "SetXRMCustomButton",
            title: button.name,
            type: "button",
            cssClass: "btn-light btn-icon border btn-sx-custom-button",
            iconClass: button.icon,
            iconColor: button.iconColor,
            attributes: {
              "data-name": button.name,
              "data-id": button.publicId,
              "data-formulaname": button.formulaName,
              "data-buttontypeid": button.buttonTypeId,
              "data-buttontypename": button.buttonTypeName,
              "data-businessruleid": button.businessRulePublicId,
              "data-businessrulename": button.businessRuleName,
              "data-fieldid": button.fieldPublicId,
              "data-fieldname": button.fieldName,
              "data-staticlink": button.staticLink,
              "data-customobjectid": button.customObjectPublicId,
            },
          };

          buttons.push(buttonObj);
        });
      }

      return buttons;
    },
    createChildren(parent) {
      const parentId = parent ? parent.itemData.id : "";
      if (String.isNullOrWhiteSpace(parentId)) {
        //parent load
        var items = this.$root.gridDataChange(
            this.listData.pagedItems.items,
            this.fields
          ),
          parentTitleFieldPublicId = this.treeViewDetail.headerFieldPublicId,
          parentTitleField = this.fields.find(
            (f) => f.publicId == parentTitleFieldPublicId
          );

        if (!parentTitleField) {
          createToast(
            this.$t(
              "Components.TreeView.TreeViewLayerTitleNotFound",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("FIELD_NAME", this.treeViewDetail.headerFieldName),
            {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            }
          );
          return [];
        }

        var parentTitles = [],
          buttons = this.getButtons(this.listData);
        items.forEach((item) => {
          var text = item[parentTitleField.formulaName];

          buttons = this.filteredHideButtons(item, this.listData, buttons);

          parentTitles.push({
            hasItems: true,
            id: item[this.primaryKey],
            text: String.isNullOrWhiteSpace(text)
              ? this.$valueNotAvailableSeparator
              : text,
            parentId: null,
            parentText: null,
            record: item,
            pageDto: this.listData,
            template: "itemTemplate",
            actionButtons: buttons,
            fields: this.fields,
          });
        });
        return parentTitles;
      } else {
        var itemLevel = this.getItemLevel(parentId),
          treeItemDetail = this.treeViewDetail.treeItems[itemLevel - 1],
          isHasItems = this.treeViewDetail.treeItems[itemLevel] ? true : false;

        if (!treeItemDetail) {
          return;
        }

        return this.getChildItems(
          parent.key,
          treeItemDetail.lookupRelationPublicId
        )
          .then((response) => {
            var childPageDto = response.data;

            if (
              childPageDto.isOk &&
              childPageDto.msg === this.$viewFilterCriteriaRequiredErrorKey
            ) {
              createToast(
                this.$t(
                  "Components.TreeView.InstantRecordListingErrorForChild",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "danger",
                  transition: "zoom",
                }
              );
              return [];
            }

            if (!childPageDto.isOk) {
              createToast(childPageDto.msg, {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              });
              return [];
            }

            this.childListDatas.push({
              ...childPageDto,
              parentId: parent.itemData.id,
              parentText: parent.itemData.text,
            });

            var item = this.getDocumentJQItem(parentId);
            item
              .find(".dx-item .dx-item-content .tree-item-total")
              .html(
                this.getBadgeInfo(
                  childPageDto.pagedItems.itemCount,
                  childPageDto.customObject.name
                )
              );

            var childFields = [],
              childFilter = childPageDto.filter,
              visibleFields = childFilter.visibleFields,
              hiddenFields = childFilter.hiddenFields;

            visibleFields.forEach((filterField) => {
              childFields.push(filterField.fieldModel);
            });
            if (hiddenFields.length > 0) {
              hiddenFields.forEach((filterField) => {
                childFields.push(filterField.fieldModel);
              });
            }

            var currentNewContextMenuItem = this.contextMenuItems
              .find((f) => f.id == "new")
              .items.find(
                (f) => f.relationId == childPageDto.customObject.publicId
              );
            if (currentNewContextMenuItem) {
              currentNewContextMenuItem.visible = true;
              currentNewContextMenuItem.text = childPageDto.customObject.name;
            }

            var childItems = this.$root.gridDataChange(
                childPageDto.pagedItems.items,
                childFields
              ),
              childTitleFieldPublicId = treeItemDetail.layerViewFieldPublicId,
              childTitleField = childFields.find(
                (f) => f.publicId == childTitleFieldPublicId
              );

            if (!childTitleField) {
              createToast(
                this.$t(
                  "Components.TreeView.TreeViewLayerTitleNotFound",
                  {},
                  { locale: this.$store.state.activeLang }
                ).replaceAll("FIELD_NAME", treeItemDetail.layerViewFieldName),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "danger",
                  transition: "zoom",
                }
              );
              return [];
            }

            var childTitles = [],
              buttons = this.getButtons(childPageDto);
            childItems.forEach((item) => {
              var text = item[childTitleField.formulaName];

              buttons = this.filteredHideButtons(item, childPageDto, buttons);

              childTitles.push({
                hasItems: isHasItems,
                id: item[this.primaryKey],
                text: String.isNullOrWhiteSpace(text)
                  ? this.$valueNotAvailableSeparator
                  : text,
                parentId: parentId,
                parentText: parent.itemData.text,
                record: item,
                pageDto: childPageDto,
                template: "itemTemplate",
                actionButtons: buttons,
                fields: childFields,
              });
            });

            if (
              childItems.length == 0 &&
              itemLevel - 1 > 0 //ignore parent
            ) {
              this.childExpandedNoItems.push(parent.key);
            }

            return childTitles;
          })
          .finally(() => {
            //
          });
      }
    },
    onContentReady(e) {
      // this.treeView.repaint();
      this.$root.createEvent("SXTREE_ON_CONTENT_READY", {
        title: "On content ready",
        message: "Event triggered when tree on content ready",
        tree: {
          event: e,
        },
      });
    },
    onItemRendered(e) {
      this.$root.createEvent("SXTREE_ON_ITEM_RENDERED", {
        title: "On item rendered",
        message: "Event triggered when tree on item rendered",
        tree: {
          event: e,
        },
      });
    },
    defaultProperties() {
      this.responseError = [];
      this.listData = {};
      this.pageData = {};
      this.fields = [];
      this.treeViewPageRequestFinish = false;
      this.selectedTreeItem = null;
      this.clickedCurrentButton = null;
      this.designedItems = [];
      this.selectedInfo = {};
      this.childExpandedItems = [];
      this.childExpandedNoItems = [];
      this.contextMenuItems = [
        {
          id: "expand",
          text: this.$t(
            "Components.TreeView.Expand",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "collapse",
          text: this.$t(
            "Components.TreeView.Collapse",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "collapse-all-items",
          text: this.$t(
            "Components.TreeView.CollapseAll",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ];
    },
    async getParentItems(key, filterId = "") {
      var isAdhoc = String.isNullOrWhiteSpace(this.$route.query.isAdhoc)
        ? false
        : Boolean.toBool(this.$route.query.isAdhoc);
      var request = this.$appAxios
        .get(
          `rws-SetController-List?key=${key}&filterId=${filterId}&isAdhoc=${isAdhoc}&orderFields=&ordering=&isAsc=false&page=${this.pageNumber}&size=${this.pageSize}`
        )
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });

      return await request;
    },
    async getChildItems(parentRecordPublicId, lookupRelationPublicId) {
      var request = this.$appAxios
        .get(
          `rws-SetController-ListRelationalRecords?parentRecordPublicId=${parentRecordPublicId}&lookupRelationPublicId=${lookupRelationPublicId}&orderFields=&ordering=&isAsc=false&page=${this.pageNumber}&size=${this.pageSize}`
        )
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });

      return await request;
    },
    getTreeViewPage() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.defaultProperties();
      this.$root.getSetScripts(this.$route.params.key, "Tree").then(() => {
        this.pageProcess();
      });
    },
    pageProcess() {
      var key = this.$route.params.key,
        treeViewId = this.$route.params.treeId,
        isAdhoc = String.isNullOrWhiteSpace(this.$route.query.isAdhoc)
          ? false
          : Boolean.toBool(this.$route.query.isAdhoc);

      if (
        String.isNullOrWhiteSpace(key) ||
        String.isNullOrWhiteSpace(treeViewId)
      ) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      this.$appAxios
        .get(`rws-GetTreeView?publicId=${treeViewId}`)
        .then((response) => {
          this.treeViewDetail = response.data;
          if (!this.treeViewDetail.isOk) {
            this.$router.push({
              name: "PermissionNone",
              params: { key: key },
            });
          } else {
            this.$root.operationAfterPageLoadForSetScripts();
            this.getParentItems(
              key,
              isAdhoc
                ? this.$route.query.filterId
                : this.treeViewDetail.viewFilterPublicId
            ).then((response) => {
              var result = response.data;
              this.listData = result;

              if (
                result.isOk &&
                result.msg === this.$viewFilterCriteriaRequiredErrorKey
              ) {
                createToast(
                  this.$t(
                    "Components.TreeView.InstantRecordListingError",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
                  {
                    showIcon: true,
                    position: "top-right",
                    type: "danger",
                    transition: "zoom",
                  }
                );
                this.$router.push({
                  name: "list",
                  params: { key: this.listData.customObject.key },
                  query: {
                    filterId: this.listData.filter.publicId,
                  },
                });
                return;
              }

              if (this.$store.state.isMultiLanguage) {
                var localizationValue =
                  this.$store.getters.getLocalizationValuesByParameters({
                    parentId: null,
                    itemPublicId: this.treeViewDetail.publicId,
                  });
                if (localizationValue) {
                  this.treeViewDetail.name = localizationValue.value;
                }

                var localizationValueForPluralName =
                  this.$store.getters.getLocalizationValuesByParameters({
                    itemFormulaName: this.listData.customObject.formulaName,
                    itemTypeId: 11,
                  });
                if (localizationValueForPluralName) {
                  this.listData.customObject.pluralName =
                    localizationValueForPluralName.value;
                }
              }

              this.treeViewPageRequestFinish = true;
              if (result.isOk) {
                this.$store.commit("setPageDto", {
                  filter: result.filter,
                  customObject: result.customObject,
                  pageDetail: this.treeViewDetail,
                  isOk: true,
                });

                this.$store.commit("setPageLayoutAvailable", true);

                this.fields = [];
                result.filter.visibleFields.forEach((filterField) => {
                  this.fields.push(filterField.fieldModel);
                });

                if (result.filter.hiddenFields.length > 0) {
                  result.filter.hiddenFields.forEach((filterField) => {
                    this.fields.push(filterField.fieldModel);
                  });
                }

                this.contextMenuItems.push({
                  id: "new",
                  text: this.$t(
                    "Set.New",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
                  visible: true,
                  items: [
                    {
                      id: "parent",
                      text: this.listData.customObject.name,
                      relationId: this.listData.customObject.publicId,
                      visible: true,
                    },
                  ],
                });

                var newProcessContextMenuItems = this.contextMenuItems.find(
                  (f) => f.id == "new"
                ).items;
                this.treeViewDetail.treeItems.forEach((item) => {
                  newProcessContextMenuItems.push({
                    id: "child",
                    text: item.lookupRelationName,
                    relationId: item.customObjectPublicIdOfLookupRelation,
                    visible: false,
                  });
                });
              } else {
                this.responseError.push(result.msg);
              }

              var title = this.$t(
                "Set.TreeViewFormat",
                {},
                { locale: this.$store.state.activeLang }
              ).replaceAll("OBJECT_NAME", this.treeViewDetail.name);
              setCurrentPageBreadcrumbs(title, [
                {
                  name: this.listData.customObject
                    ? this.listData.customObject.pluralName
                    : "",
                  isRouteLink: true,
                  html: `/set/list/${key}`,
                },
                {
                  name: title,
                },
              ]);

              this.$root.changeDocumentTitle(title);
            });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
  mounted() {
    this.getTreeViewPage();
  },
};
</script>
